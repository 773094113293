/**
 * Industries page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import { ColorContainer, Column, Container, Row } from '../components/Container';
import { Page } from '../components/Page';
import { Body, HeaderH2 } from '../components/Typography';
import { IndexLayout } from '../layouts';

const WrappingRow = styled(Row)({
	flex: 1,
	flexWrap: 'wrap',
	'& :not(:last-child)': {
		marginRight: 32,
	},
});

const ImpressumPage: React.FC = () => {
	return (
		<IndexLayout>
			<Page>
				<ColorContainer backgroundColor="#fbfbfb">
					<Container>
						<WrappingRow>
							<Column>
								<HeaderH2>Impressum</HeaderH2>
							</Column>
							<Column>
								<Body>
									Firmenanschrift
									<br />
									ThingOS GmbH & Co. KG
									<br />
									Naststr. 15b
									<br />
									70184 Stuttgart
									<br />
									Telefon: +49 711 21952 500
									<br />
									Mail: info@thingos.io
									<br />
									Webseite: www.thingos.io
									<br />
									Registergericht: Stuttgart, Deutschland
									<br />
									Handelsregisternummer: HRB 764636
									<br />
									Umsatzsteuer-IDNr.: DE355784139
									<br />
								</Body>
							</Column>
							<Column>
								<Body>
									Webseite Operator
									<br />
									ThingOS GmbH & Co. KG
									<br />
									Naststr. 15b
									<br />
									70184 Stuttgart
									<br />
									Telefon: +49 711 21952 500
									<br />
									Mail: info@thingos.io
									<br />
									Webseite: www.thingos.io
									<br />
								</Body>
							</Column>
							<Column>
								<Body>
									Geschäftsführer
									<br />
									Sibylle Thierer
									<br />
									Jörg Schmid
									<br />
									Thomas Kubitza
								</Body>
							</Column>
						</WrappingRow>
					</Container>
				</ColorContainer>
				<ColorContainer>
					<Container>
						<WrappingRow>
							<Column flex>
								<HeaderH2>Datenschutzerklärung</HeaderH2>
								<Body>
									ThingOS is a software platform which runs on hub devices in the environment.
									Connect Hubs running ThingOS will directly connect to many different devices in
									the direct surroundings, bridging their differences and allowing them to smartly
									interconnect their individual capabilities. This is implemented in the form of
									Smart Space Applications (Apps for hubs). ThingOS can run on hardware as such a
									SoMs, rack hardware such as HPE EdgeLine servers as well as industrial hardware
									such as RevPis, NetPis and many others.
								</Body>
							</Column>
						</WrappingRow>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default ImpressumPage;
